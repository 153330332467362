import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import PressList from "../components/Press/PressList"
import Paginator from "../components/Paginator"
import SEO, { seoPropsMapping } from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const PressReleases = ({ data, ...props }) => {
  const posts = data.allPrismicPressReleaseEntry.edges
  const pageData = data.prismicPressReleasePage.data
  const postNodes = posts.sort(
    (a, b) =>
      new Date(b.node.data.publish_date).getTime() - new Date(a.node.data.publish_date).getTime(),
  )

  const seoProps = seoPropsMapping(pageData)

  return (
    <Layout>
      <SEO {...seoProps} />
      <Header title={"Press Releases"} />
      <section className="uk-section">
        <div className="uk-container uk-container-medium">
          <PressList posts={postNodes} />
        </div>
      </section>
      <Paginator context={props.pageContext} path={"/company/press-releases"} />
    </Layout>
  )
}

export default withPreview(PressReleases)

export const pressListQuery = graphql`
  query pressListQuery($skip: Int!, $limit: Int!) {
    allPrismicPressReleaseEntry(
      sort: { fields: data___publish_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            publish_date
            short_title
            tile_image_for_press_list {
              fixed(width: 500) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
          }
        }
      }
    }
    prismicPressReleasePage {
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
      }
    }
  }
`
